<template>
  <div id="classIn">
    <div class="top">
      <div class="flexcenter">
        <div class="left">
          <div class="title">
            <div class="title2">
              学习人次
              <helpIcon
                style="margin-left:6px;"
                content="统计网校课程的学习总人次，同一学员在不同章节学习，算多个人次；<br/>学习人次：单日内过滤重复(去重)，多日为累计"
              ></helpIcon>
            </div>
            <div class="vm font1">
              全部
              <span class="font2">
                <i>{{ study.allUser }}</i> </span
              >人次
            </div>
            <div class="vm font1">
              今日
              <span class="font2">{{ study.todayAllUser }}</span
              >人次
            </div>
          </div>
          <div class="tree">
            <treeChart :lists="dataset"></treeChart>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <div class="title2">
              学习次数
              <helpIcon style="margin-left:6px;" prompt="统计网校课程的学习总次数，学习一次累加一次；播放视频算学习行为，仅进入页面不算"></helpIcon>
            </div>
            <div class="vm font1">
              全部
              <span class="font2">{{ study2.allNumber }}</span
              >次
            </div>
            <div class="vm font1">
              今日
              <span class="font2">{{ study2.todayAllNumber }}</span
              >次
            </div>
          </div>
          <div class="tree">
            <treeChart :lists="dataset2"></treeChart>
          </div>
        </div>
      </div>
      <div class="flexcenter2">
        <div class="left">
          <div class="left2">
            <div class="title">
              <div class="title2">直播学习人次占比</div>
            </div>
            <div class="tree pie-contain" style="float:left">
              <pieChart :pieData="list1" class="treecaht1" :options="pieOption"></pieChart>
            </div>
            <div class="zi">
              <div class="font4">学习总人次：{{ study3.liveAllUser }}人</div>
              <div class="font4">极速模式连麦人次：{{ study3.fastLiveCallNum }}人</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <div class="title2">
              录播和回放学习人次占比
              <helpIcon style="margin-left:6px;" prompt="更新频率24小时"></helpIcon>
            </div>
          </div>
          <div class="tree pie-contain">
            <pieChart :pieData="list2" :options="pieOption" class="treecaht1"></pieChart>
          </div>
          <div class="zi">
            <div class="font4">学习总人次：{{ study4.videoAllUser }}人</div>
            <div class="font4">录播文件：{{ study4.videoFileCount }}个</div>
            <div class="font4">普通模式回放文件：{{ study4.playbackCount }}个</div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div style="width:100%;">
        <div style="float:left;width:200px">
          <div class="font3">学习终端系统人次占比</div>
        </div>
        <div style="float:right;">
          <el-select class="select-record" style="float:left;" v-model="value" @change="changge1" placeholder="请选择记录模式">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-radio-group style="float:left;margin:0 20px;" v-model="tabPosition" @change="tabPosition2">
            <el-radio-button label="1">全部</el-radio-button>
            <el-radio-button label="2">最近7天</el-radio-button>
            <el-radio-button label="3">最近30天</el-radio-button>
          </el-radio-group>
          <el-date-picker
            style="float:left;"
            @change="picktime"
            v-model="time"
            type="daterange"
            align="right"
            unlink-panels
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :pickerOptions="pickerOptions"
          ></el-date-picker>
        </div>
      </div>
      <div class="clear:both"></div>
      <div class="tree" style="margin-top:50px" v-if="callbackdata.type == 1 || callbackdata.type == 4 || callbackdata.type == 5">
        <pieChart :pieData="list3" class="treecaht1"></pieChart>
      </div>
      <div class="tree" style="margin-top:50px" v-if="callbackdata.type == 2 || callbackdata.type == 3">
        <pieChart :pieData="list5" class="treecaht1"></pieChart>
      </div>
    </div>
    <div class="bottom" style="position: relative;">
      <div class="top">
        <el-tabs v-model="post2.type" type="card" class="el_tab">
          <el-tab-pane v-for="item in options2" :key="item.value" :label="item.label" :name="item.value"></el-tab-pane>
        </el-tabs>
        <div style="float:right;color:rgba(153,153,153,1);font-size:12px;display:flex;flex-direction:row" v-if="post2.type > 0">
          <el-input style="margin-right:20px;width:300px" :placeholder="aa" v-model="post2.name" class="input-with-select">
            <el-select v-model="post2.search" style="width:80px" slot="prepend" @change="getkey">
              <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-input>
          <el-date-picker
            @change="picktime2"
            v-model="time2"
            type="daterange"
            style="width:271px"
            align="right"
            unlink-panels
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :pickerOptions="pickerOptions"
          ></el-date-picker>
        </div>
        <div style="color: rgb(153, 153, 153);font-size: 12px;position: absolute;top: 35px;right: 20px;" v-else>默认显示最近30天记录</div>
      </div>
      <div style="clear:both;margin-bottom:20px"></div>
      <div v-if="post2.type > 0">
        <template>
          <pagination2 :option="post2" url="/ClassAnalysis/classRecordList">
            <template v-slot:default="{ tableData }">
              <el-table
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333'
                }"
                :data="tableData.info"
                style="width: 100%"
              >
                <el-table-column prop="name" label="课程" width="180"></el-table-column>
                <el-table-column prop="schedule_name" label="章节"></el-table-column>
                <el-table-column prop="startTime" label="上课时间">
                  <template slot-scope="scope">{{ scope.row.startTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}</template>
                </el-table-column>
                <el-table-column prop="live_num" label="学习人次"></el-table-column>
                <el-table-column prop="learning_num" label="学习次数"></el-table-column>
                <el-table-column v-if="post2.type == 1" prop="playback_num" label="回放数量"></el-table-column>
                <el-table-column v-if="post2.type == 2" prop="call_num" label="连麦人数"></el-table-column>
                <el-table-column label="操作" width="80">
                  <template slot-scope="scope">
                    <el-button type="text" @click="detail(scope.row)">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
        </template>
      </div>
      <div v-else>
        <pagination2 :option="post1" url="/ClassAnalysis/classRecord">
          <template v-slot:default="{ tableData }">
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333'
              }"
              :data="tableData"
              style="width: 100%"
            >
              <el-table-column prop="c_time" label="日期" width="180">
                <template slot-scope="scope">{{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}</template>
              </el-table-column>
              <el-table-column prop="today_visit_user_num" label="访客人次"></el-table-column>
              <el-table-column prop="live_num" label="直播上课人次"></el-table-column>
              <el-table-column prop="video_num" label="录播观看人次"></el-table-column>
              <el-table-column prop="playback_num" label="回放观看人次"></el-table-column>
              <el-table-column prop="private_num" label="小班上课人次"></el-table-column>
              <el-table-column prop="fast_live_num" label="极速模式上课人次"></el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import pieChart from '@/components/dataAnalysis/pieChart'
import treeChart from '@/components/dataAnalysis/tree'
export default {
  name: 'classIn',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        }
      },
      time: '',
      time2: '',
      options3: [
        {
          value: 1,
          label: '课程'
        },
        {
          value: 2,
          label: '章节'
        }
      ],
      value3: '',
      post1: {},
      post2: {
        type: '1',
        startTime: '',
        endTime: '',
        name: '',
        search: 1
      },
      aa: '输入课程名称搜索',
      select: '',
      modell: false,
      record1: true,
      list: [],
      options2: [
        {
          value: '1',
          label: '普通模式记录'
        },
        {
          value: '2',
          label: '极速模式记录'
        },
        {
          value: '3',
          label: '小班直播记录'
        }
      ],
      value2: '',
      show: 1,
      activeName: '1',
      tabPosition: 1,
      options: [
        {
          value: '1',
          label: '普通模式'
        },
        {
          value: '2',
          label: '极速模式'
        },
        {
          value: '3',
          label: '小班直播'
        },
        {
          value: '4',
          label: '录播'
        },
        {
          value: '5',
          label: '直播回放'
        }
      ],
      value: '',
      callbackdata: {
        type: 1,
        timeType: 1,
        startTime: '',
        endTime: ''
      }, //学习终端返回参数
      list1: [],
      list2: [],
      list3: [],
      list5: [],
      study: [], //学习人次
      study2: [], //学习次数
      study3: [], //直播观看占比
      study4: [], //录播和回放占比
      dataset: [],
      dataset2: [],

      pieOption: {}
    }
  },
  components: {
    pieChart,
    helpIcon,
    treeChart
  },
  created() {
    this.getData()
    this.getData2()
    // this.value2 = this.options2[0].label;
    this.value = this.options[0].value
    this.value3 = this.options3[0].label
  },
  methods: {
    picktime2(val) {
      // console.log(val);
      try {
        this.post2.startTime = val[0] / 1000
        this.post2.endTime = val[1] / 1000 + 86399
      } catch {
        this.post2.startTime = ''
        this.post2.endTime = ''
      }
    },
    picktime(val) {
      // console.log(val);
      try {
        this.callbackdata.startTime = val[0] / 1000
        this.callbackdata.endTime = val[1] / 1000 + 86399
        this.tabPosition = ''
      } catch {
        this.callbackdata.startTime = ''
        this.callbackdata.endTime = ''
        this.tabPosition = 1
      }
      this.callbackdata.timeType = val ? 4 : 1
      this.getData2()
    },
    tabPosition2(val) {
      this.callbackdata.timeType = val
      this.time = ''
      this.callbackdata.startTime = ''
      this.callbackdata.endTime = ''
      this.getData2()
    },
    changge1(val) {
      this.callbackdata.type = val
      this.getData2()
    },
    // 上课分析
    getData() {
      this.$http({
        url: '/ClassAnalysis/foundatio',
        callback: ({ data, code }) => {
          if (code == 200) {
            const list = data.classInfo
            this.dataset = [
              ['product', '本月', '上月'],
              ['普通模式', list.thisMonthLiveUser, list.lastMonthLiveUser],
              ['极速模式', list.thisMonthFastLiveUser, list.lastMonthFastLiveUser],
              ['录播课', list.thisMonthVideoUser, list.lastMonthVideoUser],
              ['小班课', list.thisMonthPrivateUser, list.lastMonthPrivateUser],
              ['回放', list.thisMonthPlaybackUser, list.lastMonthPlaybackUser]
            ]
            this.dataset2 = [
              ['product', '本月', '上月'],
              ['普通模式', list.thisMonthLiveNumber, list.lastMonthLiveNumber],
              ['极速模式', list.thisMonthFastLiveNumber, list.lastMonthFastLiveNumber],
              ['录播课', list.thisMonthVideoNumber, list.lastMonthVideoNumber],
              ['小班课', list.thisMonthPrivateNumber, list.lastMonthPrivateNumber],
              ['回放', list.thisMonthPlaybackNumber, list.lastMonthPlaybackNumber]
            ]
            this.study = {
              allUser: list.allUser,
              todayAllUser: list.todayAllUser
            }
            this.study2 = {
              allNumber: list.allNumber,
              todayAllNumber: list.todayAllNumber
            }
            this.list1 = [
              {
                value: list.ordinaryLiveAllUser,
                name: '普通模式',
                itemStyle: { color: '#0098FF' }
              },
              {
                value: list.fastLiveallUser,
                name: '极速模式',
                itemStyle: { color: '#F8B832' }
              },
              {
                value: list.privateAllUser,
                name: '小班直播',
                itemStyle: { color: '#66B358' }
              }
            ]
            this.list2 = [
              {
                value: list.videoFileAllUser,
                name: '录播',
                itemStyle: { color: '#0098FF' }
              },
              {
                value: list.playbackAllUser,
                name: '直播回放',
                itemStyle: { color: '#F8B832' }
              }
            ]
            this.study3 = {
              liveAllUser: list.liveAllUser,
              fastLiveCallNum: list.fastLiveCallNum
            }
            this.study4 = {
              videoAllUser: list.videoAllUser,
              videoFileCount: list.videoFileCount,
              playbackCount: list.playbackCount
            }
          }
        }
      })
    },
    // 学习终端占比
    getData2() {
      this.$http({
        url: 'ClassAnalysis/viewingTerminal',
        data: this.callbackdata,
        callback: ({ data, code }) => {
          if (code == 200) {
            const list3 = data
            this.list3 = [
              {
                value: list3.h5_uv_num,
                name: '微信H5',
                itemStyle: { color: '#F8B832' }
              },
              {
                value: list3.xcx_uv_num,
                name: '小程序',
                itemStyle: { color: '#0098FF' }
              },
              {
                value: list3.pc_uv_num,
                name: '电脑',
                itemStyle: { color: '#00D966' }
              }
            ]
            this.list5 = [
              {
                value: list3.xcx_uv_num,
                name: '小程序',
                itemStyle: { color: '#0098FF' }
              },
              {
                value: list3.pc_uv_num,
                name: '电脑',
                itemStyle: { color: '#00D966' }
              }
            ]
          }
        }
      })
    },

    //获取关键字
    getkey(val) {
      switch (val) {
        case 1:
          this.aa = '输入课程名称搜索'
          break
        case 2:
          this.aa = '输入章节名称搜索'
          break
      }
    },

    detail(value) {
      // console.log('classIn', Math.random())
      // ?sl_id=525&type=1 直播
      // ?sl_id=512&type=5 极速直播
      // ?sr_id=19965&type=3 录播
      // ?sp_id=19809&type=2 小班
      let path
      switch (Number(this.post2.type)) {
        // 极速模式
        case 2:
          path = `/courseLive/EditCourse/dataAnalysisDetail?sl_id=${value.sl_id}&type=5`
          break
        // 小班
        case 3:
          path = `/coursePrivate/EditCourse/dataAnalysisDetail?sp_id=${value.sp_id}&type=2`
          break
        // 默认普通
        default:
          path = `/courseLive/EditCourse/dataAnalysisDetail?sl_id=${value.sl_id}&type=1`
      }
      this.$router.push({ path })
    }
  }
}
</script>
<style lang="scss" scoped>
.top {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  .flexcenter {
    display: flex;
    .left {
      flex: 1;
    }
    .right {
      flex: 1;
    }
  }
  .flexcenter2 {
    display: flex;
    margin-top: 61px;
    .left,
    .right {
      flex: 1;
      margin-right: 60px;
      position: relative;
      .zi {
        position: absolute;
        top: 36%;
        right: -11px;
      }
    }
  }
  .font4 {
    margin-bottom: 10px;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    line-height: 16px;
  }
  .tree {
    height: 300px;
    width: 100%;
    position: relative;
  }
  .pie-contain {
    height: 220px;
  }
  .el-row:first-child {
    margin-bottom: 50px;
  }
  .left {
    float: left;
    // margin-right: 216px;
  }
  .font1 {
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
    line-height: 16px;
    margin-right: 38px;
    margin-top: 10px;
  }
  .font2 {
    margin: 5px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 31px;
  }

  .title {
    font-size: 0;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
    .title2 {
      display: flex;
      font-weight: bold;
      font-size: 14px;
      flex-direction: row;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;
    }
  }
}
.center {
  height: 358px;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  .tree {
    margin: 0 auto;
    height: 200px;
    width: 300px;
    position: relative;
    .treecaht1 {
      height: 300px;
      width: 100%;
    }
  }
  .font3 {
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
  ::v-deep .el-input,
  ::v-deep .el-range-input {
    font-size: 12px;
    line-height: 30px;
  }
  ::v-deep .el-range-separator,
  ::v-deep .el-date-editor .el-range__icon,
  ::v-deep .el-date-editor .el-range__close-icon {
    line-height: 29px;
  }
  ::v-deep .el-form-item__content {
    height: 36px;
  }
  ::v-deep .el-input__inner,
  ::v-deep .el-input {
    width: 120px;
    height: 36px;
  }
  ::v-deep .el-date-editor {
    width: 220px;
    height: 36px;
  }
  ::v-deep .el-form-item {
    margin: 0;
  }

  ::v-deep .el-input,
  ::v-deep .el-range-input {
    font-size: 12px;
    line-height: 36px;
  }
}
.bottom {
  // height: 420px;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  // .el-select {
  //   ::v-deep .el-input__inner {
  //     border: 0;
  //   }
  // }

  .font5 {
    float: left;
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    &.selected {
      color: $color;
    }
  }
}
.top {
  ::v-deep .el-select .el-input__inner {
    font-size: 14px;
    font-weight: bold;
    width: 110px;
    border: 0;
    color: rgba(51, 51, 51, 1);
  }
  ::v-deep .el-input,
  ::v-deep .el-range-input {
    font-size: 12px;
    line-height: 36px;
  }
}
::v-deep .selectedRecordModel .el-select .el-input__inner {
  color: $color;
  width: 140px;
}
::v-deep .el-radio-button__inner,
::v-deep .el-select-dropdown__item {
  font-size: 12px;
}
::v-deep .el-radio-button__inner {
  height: 36px;
  font-size: 12px;
  line-height: 11px;
}
::v-deep .select-record .el-input__icon {
  line-height: 35px;
}
::v-deep .el_tab {
  margin-bottom: 20px;
  .el-tabs__header {
    border: 0;
  }
  .el-tabs__nav {
    border-bottom: 1px solid #e4e7ed;
  }
}
</style>
